import React from "react";
const IndexPage = () => <></>;
export default IndexPage;

// Export gatsby head
export const Head = () => (
  <>
    <title>Emily Buchberger</title>
    <meta
      name="description"
      content="A portfolio website for Emily Buchberger, a designer in Chicago, Illinois."
    />
  </>
);
